import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./service/auth";

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import LooseDirectMailBackgroundList from './pages/LooseDirectMailBackgroundList'
import LooseDirectMailBackgroundCreate from './pages/LooseDirectMailBackgroundCreate'

// Report

import ReportByDate from './pages/ReportByDate'

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		exact
		render={props =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
					<Redirect to={{ pathname: "/", state: { from: props.location } }} />
				)
		}
	/>
);

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Login} />

			<PrivateRoute path="/dashboard" component={Dashboard} />
			<PrivateRoute path="/loose-direct-mail-background-list" component={LooseDirectMailBackgroundList} />
			<PrivateRoute path="/loose-direct-mail-background-create" component={LooseDirectMailBackgroundCreate} />

			<PrivateRoute path="/report-by-date" component={ReportByDate} />

			<Route path="*" component={() => <h1>Página não encontrada.</h1>} />
		</Switch>
	</BrowserRouter>
);

export default Routes;