import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import { logout } from '../../service/auth'

const CustomNavbar = (props) => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" style={{marginBottom:60}}>
            <Navbar.Brand
                style={{
                    cursor: 'pointer'
                }}
                onClick={() => props.history.push('/dashboard')}
            >
                Painel C.R.M
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link 
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => props.history.push('/dashboard')}
                    >Home
                    </Nav.Link>
                    <NavDropdown title="Backgrounds Diamante Azul" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => props.history.push('/loose-direct-mail-background-list')}
                        >
                            Listar Todos
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item 
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => props.history.push('/loose-direct-mail-background-create')}
                        > Adicionar Novo
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Relatórios" id="basic-nav-dropdown">
                        <NavDropdown.Item 
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => props.history.push('/report-by-date')}
                        >
                            Por Data
                        </NavDropdown.Item>
                        {/* <NavDropdown.Divider />
                        <NavDropdown.Item 
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => props.history.push('/user-create')}
                        >
                            Adicionar Novo
                        </NavDropdown.Item> */}
                    </NavDropdown>
                </Nav>
                
                <NavDropdown
                    title="Configurações"
                >
                    {/* <NavDropdown.Item href="#action/3.1">Editar Dados</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            logout()
                            props.history.push('/')
                        }}
                    >Sair</NavDropdown.Item>
                </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withRouter(CustomNavbar)