import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import ShowInfo from "../../components/ShowInfo";
import MainContentSpaced from "../../components/MainContentSpaced";

import api from "../../service/api";

const LooseDirectMailBackgroundCreate = (props) => {
    const [showTrips, setShowTrips] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [name, setName] = useState(null);
    const [file, setFile] = useState(null);
    const [haveVideo, setHaveVideo] = useState(false);
    const [videoLink, setVideoLink] = useState("");
    const [isBirthday, setIsBirthday] = useState(false);

    useEffect(function () {}, [props.history]);

    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image_background", file);
        formData.append("name", name);
        formData.append("haveVideo", haveVideo);
        formData.append("videoLink", videoLink);
        formData.append("isBirthday", isBirthday);

        async function postLooseDirectMailBackgroundCreate() {
            const responseLooseDirectMailBackgroundCreate = await api.post(
                "/loose-direct-mail-background",
                formData
            );

            if (
                responseLooseDirectMailBackgroundCreate.data.error ||
                responseLooseDirectMailBackgroundCreate.status === 500
            ) {
                setShowAlert(true);
                setAlertTitle(
                    "Problemas ao criar o Background do Diamante Azul."
                );
                setAlertText(
                    `Tivemos problemas ao tentar criar o Background do Diamante Azul. Por favor contate o suporte!`
                );
                setAlertVariant("danger");
            } else {
                setShowAlert(true);
                setAlertTitle(
                    "Background do Diamante Azul criado com sucesso!"
                );
                setAlertText(
                    `Voce sera automaticamente direcionado para a lista`
                );
                setAlertVariant("success");

                setTimeout(() => {
                    props.history.push("/loose-direct-mail-background-list");
                }, 5000);
            }
        }

        postLooseDirectMailBackgroundCreate();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nome Background</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nome Background"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o nome desejado para o fundo.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formFile">
                                    <Form.Label>Imagem de Fundo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            setFile(e.target.files[0]);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Escolha a imagem correta de fundo.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formHaveVideo">
                                    <Form.Check
                                        type="checkbox"
                                        label="Contém vídeo nessa mala direta?"
                                        checked={haveVideo}
                                        onChange={() =>
                                            setHaveVideo(!haveVideo)
                                        }
                                    />
                                </Form.Group>

                                <Form.Group controlId="formVideoLink">
                                    <Form.Label>Link do vídeo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Link do vídeo"
                                        value={videoLink}
                                        onChange={(e) => {
                                            setVideoLink(e.target.value);
                                        }}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o link do vídeo.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="formIsBirthday">
                                    <Form.Check
                                        type="checkbox"
                                        label="Mala direta para aniversário?"
                                        checked={isBirthday}
                                        onChange={() =>
                                            setIsBirthday(!isBirthday)
                                        }
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit">
                                    Criar!
                                </Button>
                            </Form>
                        </Col>
                        {showTrips ? (
                            <Col>
                                <Card>
                                    <Card.Header>Dicas</Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            Use essas dicas durante o cadastro:
                                        </Card.Title>
                                        <Card.Text>
                                            <div>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        - Lembre-se que o nome
                                                        utilizado aparecerá para
                                                        o usuário escolher.
                                                    </li>
                                                    <li>
                                                        - Escolha a imagem
                                                        correta.
                                                    </li>
                                                </ul>
                                            </div>
                                        </Card.Text>
                                        <Button
                                            variant="warning"
                                            onClick={() => setShowTrips(false)}
                                        >
                                            Não quero mais ver as dicas!
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(LooseDirectMailBackgroundCreate);
