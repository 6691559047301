import React, { Component, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import api from "../../service/api";
import { login, isAuthenticated } from "../../service/auth";

import { Button, Form, Container, Row, Col } from 'react-bootstrap'

// mano procura usar as function components assim:
const Login = (props) => {
	const [email, setEmail] = useState(null)
	const [password, setPassword] = useState(null)
	const [error, setError] = useState(null)

	useEffect(function () {
		if (isAuthenticated()) {
			props.history.push('/dashboard')
			return
		}
	}, [props.history])

	async function handleLogin (e) {
		e.preventDefault()
		
		if (!email || !password) {
			setError("Preencha E-mail e senha para continuar!");
		} else {
			try {
				const response = await api.post("/login-email-password", { email, password });
				
				login(response.data.token);
				// login('sdfhskjdfhkjsdhfjkshdkjshdf');
				props.history.push("/dashboard");
			} catch (err) {
				setError("Houve um problema com o login, verifique suas credenciais");
			}
		}
	}

	return (
		<Container>
			<h1 className="text-center">Painel C.R.M</h1>
			<Row>
				<Col></Col>
				<Col
					style={{
						marginTop: 50
					}}
				>
					<Form onSubmit={handleLogin}>
						{error && <p>{error}</p>}
						<Form.Group controlId="formCPF">
							<Form.Label>E-mail</Form.Label>
							<Form.Control
								type="email"
								placeholder="Digite seu E-mail"
								onChange={e => setEmail(e.target.value)}
							/>
							<Form.Text className="text-muted">
								Digite seu E-mali corretamente.
							</Form.Text>
						</Form.Group>

						<Form.Group controlId="formPassword">
							<Form.Label>Senha</Form.Label>
							<Form.Control
								type="password"
								placeholder="Digite sua senha"
								onChange={e => setPassword(e.target.value)}
							/>
						</Form.Group>
						<Button
							type="submit"
							size="lg"
							variant="outline-primary"
							style={{
								width: '100%'
							}}
						>
							Entrar
						</Button>
					</Form>
				</Col>
				<Col></Col>
			</Row>
		</Container>
	);
}

export default withRouter(Login)