import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
    Container,
    Row,
    Col,
    Button,
    Breadcrumb,
    Spinner,
    Card,
    Form,
    Alert,
} from "react-bootstrap";

import CustomNavbar from "../../components/CustomNavbar";
import CustomFooter from "../../components/CustomFooter";
import MainContentSpaced from "../../components/MainContentSpaced";
import ShowInfo from "../../components/ShowInfo";

import api from "../../service/api";

const ReportByDate = (props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [fileURLLooseDirectMails, setFileURLLooseDirectMails] = useState("");
    const [fileNameLooseDirectMails, setFileNameLooseDirectMails] =
        useState("");
    const [btnCaption, setBtnCaption] = useState("Gerar!");

    const dateBeginLooseDirectMails = useRef(null);
    const dateEndLooseDirectMails = useRef(null);
    const emailToSendLooseDirectMails = useRef(null);

    useEffect(
        function () {
            var date = new Date();

            var formatedDate = `${date.getFullYear()}-${
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
            }-${date.getDate()}`;

            dateBeginLooseDirectMails.current.value = formatedDate;
            dateEndLooseDirectMails.current.value = formatedDate;
        },
        [props.history]
    );

    function onSubmitReportLooseDirectMails(event) {
        event.preventDefault();

        setBtnCaption("Gerando...");
        async function getReportLooseDirectMails() {
            try {
                const responseGetReportLooseDirectMailsByDate = await api.get(
                    `/reports/loose-direct-mail-by-date/${dateBeginLooseDirectMails.current.value}/${dateEndLooseDirectMails.current.value}/${emailToSendLooseDirectMails.current.value}`
                );

                if (
                    responseGetReportLooseDirectMailsByDate.data.error ||
                    responseGetReportLooseDirectMailsByDate.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao gerar o relatório.");
                    setAlertText(
                        responseGetReportLooseDirectMailsByDate.data.data
                            .message
                    );
                    setAlertVariant("danger");

                    setBtnCaption("Gerar!");
                } else {
                    // let fileName =
                    //     responseGetReportLooseDirectMailsByDate.headers[
                    //         "content-disposition"
                    //     ].split(";")[1];
                    // fileName = fileName.slice(
                    //     fileName.indexOf('"'),
                    //     fileName.length - 1
                    // );

                    // setFileURLLooseDirectMails(
                    //     window.URL.createObjectURL(
                    //         new Blob([
                    //             responseGetReportLooseDirectMailsByDate.data,
                    //         ])
                    //     )
                    // );
                    // setFileNameLooseDirectMails(fileName);

                    setShowAlert(true);
                    setAlertTitle(
                        "Relatório de malas diretas iniciado a geração"
                    );
                    setAlertText(
                        `Em alguns minutos chegará no e-mail informado!`
                    );
                    setAlertVariant("success");

                    // setBtnCaption("Gerar!");
                }
            } catch (err) {
                console.log(err);
                setShowAlert(true);
                setAlertTitle("Problemas ao gerar o relatório.");
                setAlertText("Por favor contate o suporte!");
                setAlertVariant("danger");

                setBtnCaption("Gerar!");
            }
        }

        getReportLooseDirectMails();
    }

    return (
        <>
            <CustomNavbar />
            <MainContentSpaced>
                <Container>
                    <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.history.push("/dashboard")
                                    }
                                >
                                    Dashboard
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Relatório por Data
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Mala Direta</Card.Header>
                                <Card.Body>
                                    <Card.Title>Escolha as Datas</Card.Title>
                                    <Form
                                        onSubmit={
                                            onSubmitReportLooseDirectMails
                                        }
                                    >
                                        <Form.Row>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Inicial"
                                                    type="date"
                                                    ref={
                                                        dateBeginLooseDirectMails
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="Data Final"
                                                    type="date"
                                                    ref={
                                                        dateEndLooseDirectMails
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    placeholder="E-mail"
                                                    type="email"
                                                    ref={
                                                        emailToSendLooseDirectMails
                                                    }
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Row
                                            style={{
                                                marginTop: 20,
                                            }}
                                        >
                                            <Col>
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={
                                                        btnCaption !== "Gerar!"
                                                    }
                                                >
                                                    {btnCaption !== "Gerar!" ? (
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : null}
                                                    {btnCaption}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {fileURLLooseDirectMails !== "" ? (
                                        <Row
                                            style={{
                                                marginTop: 20,
                                            }}
                                        >
                                            <Col>
                                                <a
                                                    href={
                                                        fileURLLooseDirectMails
                                                    }
                                                    download={
                                                        fileNameLooseDirectMails
                                                    }
                                                >
                                                    <Button variant="success">
                                                        Download .xlsx
                                                    </Button>
                                                </a>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </MainContentSpaced>
            <CustomFooter />
        </>
    );
};

export default withRouter(ReportByDate);
