import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '@empd/reactable'
import '@empd/reactable/lib/styles.css'

import { Container, Row, Col, ButtonGroup, Button, Breadcrumb, Spinner, Alert, Modal, Image } from 'react-bootstrap' 

import CustomNavbar from '../../components/CustomNavbar'
import CustomFooter from '../../components/CustomFooter'
import ShowInfo from '../../components/ShowInfo'

import api from "../../service/api";

const LooseDirectMailBackgroundList = (props) => {
    const [looseDirectMailBackgrounds, setLooseDirectMailBackgrounds] = useState([])
    const [emptyDataElement, setEmptyDataElement] = useState(<Spinner animation="grow" />)
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [looseDirectMailBackgroundView, setLooseDirectMailBackgroundView] = useState({});
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('') 

    const handleCloseModalInfo = () => setShowModalInfo(false);
    const handleShowModalInfo = () => setShowModalInfo(true);
    
    useEffect(function () {
        async function loadLooseDirectMailBackgrounds () {
            const responseLooseDirectMailBackgrounds = await api.get('/loose-direct-mail-background') 
            if (responseLooseDirectMailBackgrounds.data.error) {
                alert('Erro ao buscar as empresas. Contate o suporte!')
                setTimeout(function () {
                    props.history.push('/dashboard')
                }, 2000)
            } else {
                if (responseLooseDirectMailBackgrounds.data.data.length === 0)
                    setEmptyDataElement(
                        <Alert variant="dark">
                            Não existem registros.
                        </Alert>
                    )
                else
                    setLooseDirectMailBackgrounds(responseLooseDirectMailBackgrounds.data.data)
            }
        }

        loadLooseDirectMailBackgrounds()
    }, [props.history])

    return (
        <>
            <CustomNavbar/>
            <main>
                <Modal show={showModalInfo} onHide={handleCloseModalInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>Background Diamante Azul</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Image
                            src={'https://rxy-imagens.s3.amazonaws.com/mala_direta_backgrounds/' + looseDirectMailBackgroundView.backgroundName}
                            className="img-fluid"
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCloseModalInfo}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                <Row>
                        <Col>
                            <ShowInfo
                                title={alertTitle}
                                text={alertText}
                                show={showAlert}
                                variant={alertVariant}
                                closeAlert={() => setShowAlert(false)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push('/dashboard')}
                            >
                                Dashboard
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Listar Backgrounds Diamante Azul</Breadcrumb.Item>
                        </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                searchPlaceholder="Procurar"
                                emptyPlaceholder={emptyDataElement}                                
                                columns={
                                    [
                                        {
                                            name: 'Nome',
                                            sortable: true,
                                            selector: 'name'
                                        },
                                        {
                                            name: 'Opções',
                                            cell: function (cellInfo) {
                                                return (
                                                    <ButtonGroup aria-label="Basic example">
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                setLooseDirectMailBackgroundView(cellInfo)
                                                                handleShowModalInfo()
                                                            }}
                                                        >
                                                            Visualizar
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                var trash = window.confirm('Para Excluir pressione OK!')
                                                                if(trash === true){
                                                                    async function deleteLooseDirectMailBackground(id) {
                                                                        try {
                                                                            const responseDeleteLooseDirectMailBackground = await api.delete(`/loose-direct-mail-background/${id}`)
                                                                            if (responseDeleteLooseDirectMailBackground.status === 204) {
                                                                                setShowAlert(true)
                                                                                setAlertTitle('Background excluído com sucesso!')
                                                                                setAlertText(`O background ${cellInfo.name} foi excluído com sucesso!`)
                                                                                setAlertVariant('success')

                                                                                const updatedList = looseDirectMailBackgrounds.filter((itemLooseDirectMailBackground, index) => itemLooseDirectMailBackground._id !== cellInfo._id)
                                                                                setLooseDirectMailBackgrounds(updatedList)
                                                                            }
                                                                        } catch (err) {
                                                                            setShowAlert(true)
                                                                            setAlertTitle('Não foi possível excluir a Empresa!')
                                                                            setAlertText(`A Empresa ${cellInfo.email} não pode ser excluído. Contate o suporte!`)
                                                                            setAlertVariant('danger')
                                                                        }
                                                                    }
                                                                    deleteLooseDirectMailBackground(cellInfo._id)
                                                                }
                                                                
                                                            }}
                                                        >                                                        
                                                            Excluir
                                                        </Button>
                                                    </ButtonGroup>
                                                )
                                            }
                                        }
                                    ]
                                }
                                data={looseDirectMailBackgrounds}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
            <CustomFooter
            />
        </>
    )
}

export default withRouter(LooseDirectMailBackgroundList)